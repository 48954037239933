import React from 'react'
import { Link } from '../link'
import { Button } from '../button'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { Consumer } from '../../store/createContext'

const ServicesHeading = styled.h6`
  color: ${colors.quaternary.default};
  margin-bottom: 16px;
`

const ServicesList = styled.ul`
  list-style: none;
  padding: 0;
  font-family: 'Founders Grotesk Mono';
  font-size: 14px;

  a {
    text-decoration: none;
    color: ${colors.primary.default};
  }

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: ${props =>
      props.twoColumns ? 'repeat(2, 1fr)' : '1fr'};
  }

  li {
    margin-right: 10px;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`

const breakHeading = heading => {
  return heading.replace(' ', '<br/>')
}

const CardStyles = styled.div`
  display: grid;
  height: 100%;

  h4 a,
  li a span {
    color: ${props => (props.dark ? '#fff' : 'inherit')};
  }

  p:last-of-type {
    margin-bottom: 40px;
  }
`

const Services = styled.div`
  align-self: end;
`

const LinkHeading = styled.h4`
  a {
    text-decoration: none;
    color: ${colors.primary.default};
  }
`

export const Card = props => (
  <Consumer>
    {({ dark }) => (
      <CardStyles dark={dark}>
        <div>
          {props.to && (
            <LinkHeading>
              <Link
                to={props.to}
                dangerouslySetInnerHTML={{
                  __html: props.twoLines
                    ? breakHeading(props.heading)
                    : props.heading,
                }}
              />
            </LinkHeading>
          )}
          {!props.to && (
            <h4
              dangerouslySetInnerHTML={{
                __html: props.twoLines
                  ? breakHeading(props.heading)
                  : props.heading,
              }}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
        {props.services && (
          <Services dark={dark}>
            <ServicesHeading>Services</ServicesHeading>
            <ServicesList twoColumns={props.splitList}>
              {props.services.map((service, i) => (
                <li key={i}>
                  {props.to ? (
                    <Button to={props.to} medium>
                      {service}
                    </Button>
                  ) : (
                    <span>{service}</span>
                  )}
                </li>
              ))}
            </ServicesList>
          </Services>
        )}
      </CardStyles>
    )}
  </Consumer>
)
