import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import { Section } from '../components/sections'
import { Grid } from '../components/grid'
import { Card } from '../components/card'
import { CallToAction } from '../components/sections/callToAction'

import styled from 'styled-components'
import { colors } from '../styles/colors'

const IntroGrid = styled(Grid)`
  padding-bottom: 10px;
  border-bottom: 1px ${colors.quaternary.default} solid;
  margin-bottom: 50px;
`

const ServicesPage = ({ data }) => (
  <StaticQuery
    query={graphql`
      query ServicesPageQuery {
        markdownRemark(frontmatter: { path: { eq: "/services" } }) {
          html
          frontmatter {
            title
            services {
              heading
              content
              highlights
            }
            cta {
              heading
              link {
                text
                url
              }
            }
            seoTitle
            seoDescription
            seoImage
          }
        }
        seo: file(
          relativePath: { eq: "seo.md" }
          sourceInstanceName: { eq: "data" }
        ) {
          data: childMarkdownRemark {
            frontmatter {
              image
              description
              siteTitle
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            {
              name: 'description',
              content:
                data.markdownRemark.frontmatter.seoDescription ||
                data.markdownRemark.excerpt ||
                data.seo.data.frontmatter.description,
            },
            {
              name: 'og:image',
              content:
                'https://madebypioneer.com' +
                (data.markdownRemark.frontmatter.seoImage ||
                  data.seo.data.frontmatter.image),
            },
          ]}
          title={`${data.markdownRemark.frontmatter.seoTitle ||
            data.markdownRemark.frontmatter.title} | ${
            data.seo.data.frontmatter.siteTitle
          }`}
        />
        <Section>
          <IntroGrid
            tablet={{ columns: 'repeat(2, 1fr)', gap: '100px' }}
            desktop={{ columns: 'repeat(2, 1fr)', gap: '100px' }}
            offset={2}
          >
            <h1 className={'h2'}>{data.markdownRemark.frontmatter.title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          </IntroGrid>
          {data.markdownRemark.frontmatter.services.length && (
            <Grid
              tablet={{ columns: 'repeat(2, 1fr)', gap: '50px' }}
              desktop={{ columns: 'repeat(2, 1fr)', gap: '50px' }}
              offset={2}
            >
              {data.markdownRemark.frontmatter.services.map((service, i) => (
                <Card
                  key={i}
                  heading={service.heading}
                  content={service.content}
                  services={service.highlights}
                  splitList={true}
                />
              ))}
            </Grid>
          )}
        </Section>
        <Section>
          <CallToAction
            small={true}
            paddingBottom={'50px'}
            {...data.markdownRemark.frontmatter.cta}
          />
        </Section>
      </Layout>
    )}
  />
)

export default ServicesPage
