import React from 'react'
import { Button } from '../../button'

import styled from 'styled-components'

const CTA = styled.div`
  max-width: 700px;
  margin: auto;
  text-align: center;
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom : '0px'};
`

export const CallToAction = props => (
  <CTA {...props}>
    {props.small ? <h3>{props.heading}</h3> : <h2>{props.heading}</h2>}
    <Button to={props.link.url} theme={props.theme}>
      {props.link.text}
    </Button>
  </CTA>
)
